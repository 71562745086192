<template>
  <div>
    <RevInputText
      :id="id"
      v-bind="$attrs"
      data-cs-mask
      :label="nationalIdLabel"
      :model-value="value"
      name="customerID"
    />

    <div class="font-body-2 mt-3">
      {{ i18n(translations.nationalIdInformation) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputText } from '@ds/components/InputText'

import type { NationalIdCountries } from '../../../types/nationalId'
import { getNationalIdTranslation } from '../../../utils/nationalId/getNationalIdTranslation'

import translations from './InputAddressNationalId.translation'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    id?: string
    country?: NationalIdCountries | null
    value: string
  }>(),
  {
    id: 'nationalId',
    country: null,
  },
)

const i18n = useI18n()

const nationalIdLabel = computed(() => {
  return props.country
    ? i18n(getNationalIdTranslation(props.country).label)
    : i18n(translations.nationalIdRequiredLabel)
})
</script>
