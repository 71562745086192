<template>
  <div class="flex flex-col items-stretch gap-16">
    <InputAddressBirthdate
      v-if="withBirthdate"
      data-test="birthdate"
      :disabled="disabled"
      :error="errors.birthdate"
      :value="formattedBirthdate"
      @input="handleBirthdateInput"
    />

    <InputAddressNationalId
      v-if="withNationalId && country"
      :country="country"
      data-test="nationalId"
      :error="errors.nationalId"
      :value="nationalId"
      @input="handleNationalIdInput"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { formatDateToDDMMYYYY } from '@backmarket/utils/date/formatDateToDDMMYYYY'
import { formatDateToYYYYMMDD } from '@backmarket/utils/date/formatDateToYYYYMMDD'

import { NationalIdCountries } from '../../types/nationalId'

import InputAddressBirthdate from './InputAddressBirthdate/InputAddressBirthdate.vue'
import InputAddressNationalId from './InputAddressNationalId/InputAddressNationalId.vue'

const props = withDefaults(
  defineProps<{
    birthdate?: string
    nationalId?: string
    disabled?: boolean
    country?: NationalIdCountries
    errors: { nationalId?: string; birthdate?: string }
    withNationalId?: boolean
    withBirthdate?: boolean
  }>(),
  {
    birthdate: '',
    nationalId: '',
    country: undefined,
    disabled: false,
    withBirthdate: true,
    withNationalId: false,
  },
)

const emit = defineEmits(['update:birthdate', 'update:nationalId'])

const isDateSupported = ref(true)

onMounted(() => {
  // we need to check that type="date" is supported by the browser else
  // we will format manually the date to display
  const input = document.createElement('input')
  const value = 'a'
  input.setAttribute('type', 'date')
  input.setAttribute('value', value)
  isDateSupported.value = input.value !== value
})

const formattedBirthdate = computed(() => {
  try {
    return isDateSupported.value
      ? props.birthdate
      : formatDateToDDMMYYYY(props.birthdate)
  } catch {
    return props.birthdate || ''
  }
})

const handleBirthdateInput = (event: Event) => {
  const target = event.target as HTMLInputElement

  try {
    emit(
      'update:birthdate',
      target.value.includes('-')
        ? target.value
        : formatDateToYYYYMMDD(target.value),
    )
  } catch {
    emit('update:birthdate', target.value)
  }
}

const handleNationalIdInput = (event: Event) =>
  emit('update:nationalId', (event.target as HTMLInputElement).value)
</script>
